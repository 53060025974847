<template>
  <div>
    <div class="flexs">
      <el-dialog :title="$t('CK_KS_19')"
                 class="el-dialog_s"
                 :visible.sync="showFlag"
                 width="40%"
                 center
                 :before-close="handleClose1">
        <div v-html="cause"
             class="img_width"></div>
        <div class="button-s"
             style="margin-top: 30px"
             @click="checks(2)">{{$t('CK_KS_20')}}</div>
      </el-dialog>
      <div style="flex: 1">
        <!--          <div class="top-lists">-->
        <!--              <div-->
        <!--                      class="nav-view"-->
        <!--                      @click="$router.push(item.url)"-->
        <!--                      :class="index == 6 ? 'styles' : ''"-->
        <!--                      v-for="(item, index) in navList"-->
        <!--              >-->
        <!--                  {{ item.name }}-->
        <!--              </div>-->
        <!--          </div>-->
        <div style="position: relative">
          <div class="line-s"
               v-if="flags == 0"
               :class="status1==5?'class-s':''"></div>
          <div class="content">
            <div class="title">
              <div class="line"></div>
              <div class="name" v-if="userInfo.makerSpaceShow == 1">{{$t('w_1021')}}</div>
              <div class="name" v-if="userInfo.makerSpaceShow == 2">{{$t('w_1033')}}</div>
            </div>
            <div class="img_cen"
                 v-if="status == 3">
              <div class="status-img">
                <img src="@/assets/images/markerSpace/status2.png" />
              </div>
              <div class="status-size bolds">{{$t('CK_KS_21')}}</div>
              <div class="status-size">{{$t('CK_KS_22')}}</div>
              <div class="status-size">{{$t('CK_KS_23')}}</div>
            </div>
            <div class="img_cen"
                 v-if="status == 5">
              <div class="status-img">
                <img src="@/assets/images/markerSpace/status3.png" />
              </div>
              <div class="status-size bolds" v-if='userInfo.makerSpaceShow == 1||userInfo.makerSpaceShow == undefined'>{{$t('CK_KS_24')}}</div>
              <div class="status-size bolds" v-if='userInfo.makerSpaceShow == 2'>{{$t('CK_KS_159')}}</div>
              <div class="button-s"
                   style="margin-top: 30px"
                   @click="checks(1)">{{$t('CK_KS_25')}}</div>
            </div>
            <div class="img_cen"
                 v-if="status == 4">
              <div class="status-img">
                <img src="@/assets/images/markerSpace/status1.png" />
              </div>
              <div class="status-size bolds">{{$t('CK_KS_26')}}</div>
              <div class="button-s"
                   style="margin-top: 30px"
                   @click="showFlag=true">{{$t('CK_KS_25')}}</div>
              <!--              <div class="button-s" style="margin-top: 30px" @click="checks(2)">重新提交</div>-->
            </div>
            <div v-if="status == 1">
              <div class="systemTitle">
                <div class="titles">
                  <div>{{$t('CK_KS_27')}}</div>
                </div>
                <el-button class="button"
                           @click="downloads">{{$t('CK_KS_28')}}</el-button>
                <div class="text_s">
                  {{$t('CK_KS_29')}}
                </div>
              </div>
              <div class="form">
                <el-form :model="table"
                         :rules="rules"
                         ref="ruleForm"
                         label-position="top"
                         status-icon
                         class="demo-ruleForm">
                  <div style="display: flex">
                    <el-form-item :label="$t('N_I_124')"
                                  prop="memberCode">
                      <el-input class="inputs"
                                v-model="memberCode"
                                disabled="disabled"
                                :placeholder="$t('S_C_70')"></el-input>
                    </el-form-item>
                    <el-form-item style="margin-left: 50px"
                                  :label="$t('CK_KS_14')"
                                  prop="memberName">
                      <el-input class="inputs"
                                v-model="memberName"
                                disabled="disabled"
                                :placeholder="$t('S_C_70')"></el-input>
                    </el-form-item>
                  </div>
                  <div style="display: flex">
                    <el-form-item :label="$t('CK_KS_30')"
                                  prop="storeName">
                      <el-input :disabled="disableds"
                                class="inputs"
                                v-model="table.storeName"
                                :placeholder="$t('S_C_70')"></el-input>
                    </el-form-item>
                    <el-form-item style="margin-left: 50px"
                                  :label="$t('CK_KS_31')"
                                  prop="storeLevelVal">
                      <el-input class="inputs"
                                v-model="storeLevelVal"
                                disabled="disabled"
                                placeholder=""></el-input>
                    </el-form-item>
                  </div>
                  <div style="display: flex">
                    <el-form-item :label="$t('CK_KS_32')"
                                  prop="licenseName">
                      <el-input :disabled="disableds"
                                class="inputs"
                                maxlength="100"
                                v-model="table.licenseName"
                                :placeholder="$t('S_C_70')"></el-input>
                    </el-form-item>
                    <el-form-item style="margin-left: 50px"
                                  :label="$t('CK_KS_33')"
                                  prop="creditCode">
                      <el-input :disabled="disableds"
                                class="inputs"
                                maxlength="100"
                                v-model="table.creditCode"
                                :placeholder="$t('S_C_70')"></el-input>
                    </el-form-item>
                  </div>
                  <div style="display: flex">
                    <el-form-item :label="$t('CK_KS_34')"
                                  prop="legalName">
                      <el-input :disabled="disableds"
                                class="inputs"
                                v-model="table.legalName"
                                :placeholder="$t('S_C_70')"></el-input>
                      <div style="color: #999; font-size: 12px">
                        {{$t('CK_KS_36')}}
                      </div>
                    </el-form-item>
                    <el-form-item style="margin-left: 50px"
                                  :label="$t('CK_KS_35')"
                                  prop="legalIdCard">
                      <el-input :disabled="disableds"
                                class="inputs"
                                v-model="table.legalIdCard"
                                :placeholder="$t('S_C_70')"></el-input>
                    </el-form-item>
                  </div>
                  <div style="display: flex">
                    <el-form-item :label="$t('CK_KS_37')"
                                  prop="licenseAddress">
                      <el-cascader :disabled="disableds"
                                   class="area"
                                   v-model="address"
                                   @change="setAddress"
                                   :options="areaData"
                                   :props="regionParams"
                                   :placeholder="$t('S_C_70')"></el-cascader>
                    </el-form-item>
                  </div>
                  <div style="display: flex; margin-top: 10px">
                    <el-form-item label=""
                                  prop="licenseAddress">
                      <el-input :disabled="disableds"
                                class="area centers"
                                maxlength="200"
                                v-model="table.licenseAddress"
                                :placeholder="$t('S_C_27')"></el-input>
                    </el-form-item>
                  </div>
                  <div style="display: flex">
                    <el-form-item :label="$t('S_C_86')"
                                  prop="storePerson">
                      <el-input class="inputs"
                                v-model="table.storePerson"
                                :disabled="disableds"
                                :placeholder="$t('S_C_70')"></el-input>
                    </el-form-item>
                    <el-form-item style="margin-left: 50px"
                                  :label="$t('w_0052')"
                                  prop="storePhone">
                      <el-input class="inputs"
                                v-model="table.storePhone"
                                :disabled="disableds"
                                maxLength="11"
                                :placeholder="$t('S_C_70')"></el-input>
                    </el-form-item>
                  </div>
                  <div style="display: flex">
                    <el-form-item :label="$t('S_C_27')"
                                  prop="storeProvince">
                      <el-cascader class="area"
                                   v-model="address1"
                                   @change="setAddress1"
                                   :disabled="disableds"
                                   :options="areaData"
                                   :props="regionParams"
                                   :placeholder="$t('CK_KS_38')"></el-cascader>
                    </el-form-item>
                  </div>
                  <div style="display: flex; margin-top: 10px">
                    <el-form-item label=""
                                  prop="storeAddress">
                      <el-input class="area centers"
                                v-model="table.storeAddress"
                                :disabled="disableds"
                                :placeholder="$t('S_C_27')"></el-input>
                    </el-form-item>
                  </div>
                  <div style="display: flex">
                    <el-form-item :label="$t('CK_KS_41')"
                                  prop="pkBank">
                      <el-select class="inputs centers"
                                 :disabled="disableds"
                                 v-model="table.pkBank"
                                 :placeholder="$t('CK_KS_38')">
                        <el-option v-for="(item, index) in bankCardChioceList"
                                   :key="index"
                                   :label="item.bankName"
                                   :value="item.pkId"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item style="margin-left: 50px"
                                  :label="$t('CK_KS_42')"
                                  prop="bankAccount">
                      <el-input class="inputs"
                                v-model="table.bankAccount"
                                :disabled="disableds"
                                :placeholder="$t('S_C_70')"></el-input>
                    </el-form-item>
                  </div>

                  <div>
                    <el-form-item :label="$t('CK_KS_43')"
                                  prop="bankBranch">
                      <el-input class="inputs"
                                v-model="table.bankBranch"
                                :disabled="disableds"
                                :placeholder="$t('S_C_70')"></el-input>
                    </el-form-item>
                  </div>
                  <div>
                    <div style="display: flex">
                      <el-form-item :label="$t('CK_KS_44')"
                                    prop="">
                      </el-form-item>
                      <span style="
                          color: #999;
                          font-size: 12px;
                          margin: 11px 0 0 20px;
                        ">{{$t('CK_KS_45')}}</span>
                    </div>
                    <div class="upload-list">
                      <el-upload :disabled="disableds"
                                 :class="[hideUploadAdd == true ? 'hide' : '']"
                                 class="upload-demo"
                                 accept=".png, .jpg"
                                 action="#"
                                 name="storeSign,10"
                                 :http-request="uploadFile"
                                 ref="upload"
                                 :limit="fileLimits"
                                 :on-change="handleChange"
                                 :on-remove="handleRemove"
                                 :file-list="fileList"
                                 :on-exceed="handleExceed"
                                 :show-file-list="false"
                                 :headers="headers">
                        <div v-if="table.storeSign && status1==5"
                             class="img-ss imgWidth">
                          <el-image :src="table.storeSign"
                                    :preview-src-list="[table.storeSign]" />
                        </div>
                        <div class="upload"
                             v-if="table.storeSign == ''">
                          <div class="img-s">
                            <img src="@/assets/images/markerSpace/upload.png" />
                          </div>
                          <div class="texts">{{$t('CK_KS_46')}}</div>
                        </div>
                        <div style=" display: flex"
                             v-if="table.storeSign != ''">
                          <div class="img-ss imgWidth"
                               @click.stop="dialogFormVisible = false"
                               v-if="status1!=5">
                            <el-image :src="table.storeSign" />
                            <div class="box-colors centers">
                              <div v-if="!disableds"
                                   @click="delStops('storeSign', 10)"
                                   @click.stop="dialogFormVisible = false"
                                   style="color: #fff; font-size: 14px">
                                {{ $t('CK_KS_47')}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </el-upload>

                      <div style="margin-top: 10px">
                        <div class="size-s">{{$t('CK_KS_48')}}：jpg，png</div>
                        <div class="size-s">{{$t('CK_KS_49')}}</div>
                        <div class="size-s">
                          {{$t('CK_KS_50')}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="display: flex"
                       v-if="status1==5">
                    <el-form-item :label="$t('CK_KS_51')"
                                  prop="">
                      <el-input class="inputs"
                                v-model="creatTime"
                                disabled
                                placeholder=""></el-input>
                    </el-form-item>
                    <el-form-item style="margin-left: 50px"
                                  :label="$t('CK_KS_52')"
                                  prop="">
                      <el-input class="inputs"
                                v-model="checkTime"
                                disabled
                                placeholder=""></el-input>
                    </el-form-item>
                  </div>
                  <div class="systemTitle">
                    <div class="titles">
                      <div style="margin: 20px 0">{{$t('CK_KS_53')}}</div>
                    </div>
                  </div>
                  <div style="display: flex">
                    <el-form-item :label="$t('CK_KS_54')"> </el-form-item>
                    <span style="color: #999; font-size: 12px; margin: 12px 0 0 20px">{{$t('CK_KS_55')}}</span>
                  </div>
                  <div style="display: flex">
                    <div class="idCard">
                      <el-upload :disabled="disableds"
                                 :class="[hideUploadAdd == true ? 'hide' : '']"
                                 class="upload-demo"
                                 accept=".png, .jpg"
                                 action="#"
                                 name="legalCardFront,11"
                                 :http-request="uploadFile"
                                 ref="upload"
                                 :limit="fileLimits"
                                 :on-change="handleChange"
                                 :on-remove="handleRemove"
                                 :file-list="fileList"
                                 :on-exceed="handleExceed"
                                 :show-file-list="false"
                                 :headers="headers">
                        <div v-if="table.legalCardFront == ''">
                          <img src="@/assets/images/markerSpace/idCard-img1.png" />
                        </div>
                        <div v-if="table.legalCardFront && status1==5">
                          <el-image :src="table.legalCardFront"
                                    :preview-src-list="[table.legalCardFront]" />
                        </div>
                        <div style="display: flex"
                             v-if="table.legalCardFront != ''">
                          <div class="img-ss card"
                               v-if="status1!=5"
                               @click.stop="dialogFormVisible = false">
                            <img :src="table.legalCardFront" />
                            <div class="box-colors centers idCardWidth">
                              <div v-if="!disableds"
                                   @click="delStops('legalCardFront', 11)"
                                   @click.stop="dialogFormVisible = false"
                                   style="color: #fff; font-size: 14px">
                                {{$t('CK_KS_47')}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </el-upload>
                    </div>
                    <div class="idCard"
                         style="margin-left: 99px">
                      <el-upload :disabled="disableds"
                                 :class="[hideUploadAdd == true ? 'hide' : '']"
                                 class="upload-demo"
                                 accept=".png, .jpg"
                                 action="#"
                                 name="legalCardBack,12"
                                 :http-request="uploadFile"
                                 ref="upload"
                                 :limit="fileLimits"
                                 :on-change="handleChange"
                                 :on-remove="handleRemove"
                                 :file-list="fileList"
                                 :on-exceed="handleExceed"
                                 :show-file-list="false"
                                 :headers="headers">
                        <div v-if="table.legalCardBack == ''">
                          <img src="@/assets/images/markerSpace/idCard-img2.png" />
                        </div>
                        <div v-if="table.legalCardBack && status1==5">
                          <el-image :src="table.legalCardBack"
                                    :preview-src-list="[table.legalCardBack]" />
                        </div>
                        <div style="display: flex"
                             v-if="table.legalCardBack != ''">
                          <div class="img-ss card"
                               v-if="status1!=5"
                               @click.stop="dialogFormVisible = false">
                            <img :src="table.legalCardBack" />
                            <div class="box-colors centers idCardWidth">
                              <div v-if="!disableds"
                                   @click="delStops('legalCardBack', 12)"
                                   @click.stop="dialogFormVisible = false"
                                   style="color: #fff; font-size: 14px">
                                {{$t('CK_KS_47')}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </el-upload>
                    </div>
                  </div>
                  <div style="margin-top: 10px">
                    <div class="size-s">{{$t('CK_KS_48')}}：jpg，png</div>
                    <div class="size-s">{{$t('CK_KS_49')}}</div>
                    <div class="size-s">
                      {{$t('CK_KS_50')}}
                    </div>
                  </div>
                  <div style="display: flex">
                    <el-form-item :label="$t('CK_KS_56')"> </el-form-item>
                    <span style="color: #999; font-size: 12px; margin: 11px 0 0 20px">{{$t('CK_KS_45')}}</span>
                  </div>
                  <div class="upload-list">
                    <el-upload :disabled="disableds"
                               :class="[hideUploadAdd == true ? 'hide' : '']"
                               class="upload-demo"
                               accept=".png, .jpg"
                               action="#"
                               name="license,13"
                               :http-request="uploadFile"
                               ref="upload"
                               :limit="fileLimits"
                               :on-change="handleChange"
                               :on-remove="handleRemove"
                               :file-list="fileList"
                               :on-exceed="handleExceed"
                               :show-file-list="false"
                               :headers="headers">
                      <div class="upload"
                           v-if="table.license == ''">
                        <div class="img-s">
                          <img src="@/assets/images/markerSpace/upload.png" />
                        </div>
                        <div class="texts">{{$t('CK_KS_46')}}</div>
                      </div>
                      <div class="imgWidth"
                           v-if="table.license && status1==5">
                        <el-image :src="table.license"
                                  :preview-src-list="[table.license]" />
                      </div>
                      <div style=" display: flex"
                           v-if="table.license != ''">
                        <div class="img-ss imgWidth"
                             @click.stop="dialogFormVisible = false"
                             v-if="status1!=5">
                          <img :src="table.license" />
                          <div class="box-colors centers">
                            <div v-if="!disableds"
                                 @click="delStops('license', 13)"
                                 @click.stop="dialogFormVisible = false"
                                 style="color: #fff; font-size: 14px">
                              {{ $t('CK_KS_47') }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-upload>
                    <div style="margin-top: 10px">
                      <div class="size-s">{{$t('CK_KS_48')}}：jpg，png</div>
                      <div class="size-s">{{$t('CK_KS_49')}}</div>
                      <div class="size-s">
                        {{$t('CK_KS_50')}}
                      </div>
                    </div>
                  </div>
                  <div style="display: flex">
                    <el-form-item :label="$t('CK_KS_58')">
                    </el-form-item>
                    <span style="color: #999; font-size: 12px; margin: 11px 0 0 20px">{{$t('CK_KS_45')}}</span>
                  </div>
                  <div class="upload-list">
                    <el-upload ref="upload"
                               :disabled="disableds"
                               :class="{ hide: hideUploadAdd }"
                               class="upload-demo"
                               accept=".png, .jpg"
                               action="#"
                               name="storeAgreement,fileList1"
                               :before-remove="remove1"
                               :on-change="change1"
                               :before-upload="upload1"
                               :http-request="uploadFile1"
                               :limit="fileLimit"
                               :file-list="table.storeAgreementList"
                               :show-file-list="false"
                               :headers="headers">

                      <div style="display: flex; margin-right: 20px;flex-wrap: wrap;width: 800px">
                        <div class="imgWidth1"
                             style="display: flex"
                             v-if="table.storeAgreementList && status1==5">
                          <div v-for="(item, index) in table.storeAgreementList">
                            <el-image class="el-image1"
                                      :src="item"
                                      :preview-src-list="table.storeAgreementList" />

                          </div>
                        </div>
                        <div v-if="table.storeAgreementList && status1!=5"
                             style="margin-bottom:10px;"
                             class="img-ss imgWidth"
                             @click.stop="dialogFormVisible = false"
                             v-for="(item, index) in table.storeAgreementList">
                          <img :src="item" />
                          <div class="box-colors">
                            <div v-if="!disableds"
                                 @click="delStop(index)"
                                 @click.stop="dialogFormVisible = false"
                                 style="
                                color: #fff;
                                font-size: 14px;
                                margin-top: 30px;
                              ">
                              {{$t('CK_KS_47')}}
                            </div>
                          </div>
                        </div>
                        <div class="upload"
                             style="margin-left: 0px"
                             v-if="status1!=5">
                          <!--                        <template v-for="item in fileList1">-->
                          <div>
                            <div class="img-s">
                              <img src="@/assets/images/markerSpace/upload.png" />
                            </div>
                            <div class="texts">{{$t('CK_KS_59')}}</div>
                          </div>
                        </div>
                      </div>

                    </el-upload>
                    <div style="margin-top: 10px">
                      <div class="size-s">{{$t('CK_KS_48')}}：jpg，png</div>
                      <div class="size-s">{{$t('CK_KS_49')}}</div>
                      <div class="size-s">
                        {{$t('CK_KS_50')}}
                      </div>
                    </div>
                  </div>
                  <div style="display: flex;">
                    <!--                    <el-form-item  v-if="status1!=5" label="门店视频(0/3)"> </el-form-item>-->
                    <!--                      <el-form-item v-if="status1==5" label="门店视频(3/3)"> </el-form-item>-->
                    <el-form-item :label="`${$t('CK_KS_60')} (${getUserVideoSum1()}`+'/3)'"> </el-form-item>
                    <span style="color: #999; font-size: 12px; margin: 11px 0 0 20px">{{$t('CK_KS_61')}}</span>
                  </div>
                  <div class="upload-list">
                    <div style="display: flex">
                      <div class="upload">
                        <el-upload :disabled="disableds"
                                   :class="[hideUploadAdd == true ? 'hide' : '']"
                                   class="upload-demo"
                                   accept=".mp4"
                                   action="#"
                                   name="videoDoorhead,1"
                                   :http-request="uploadVideo"
                                   ref="upload"
                                   :limit="fileLimits"
                                   :on-remove="handleRemove"
                                   :file-list="fileList"
                                   :on-exceed="handleExceed"
                                   :show-file-list="false"
                                   :headers="headers">
                          <div class="upload"
                               v-if="table.videoDoorhead == ''">
                            <div class="texts titleSize">{{$t('CK_KS_62')}}</div>
                            <div class="img-s">
                              <img style="margin-top: 10px"
                                   src="@/assets/images/markerSpace/upload.png" />
                            </div>
                            <div class="texts"
                                 style="margin-top: 10px">
                              {{$t('CK_KS_63')}}
                            </div>
                          </div>
                          <div style="display: flex"
                               v-if="table.videoDoorhead != ''">
                            <div class="img-ss width"
                                 @click.stop="dialogFormVisible = false">
                              <img v-if="!disableds"
                                   @click="delStops('videoDoorhead', 1)"
                                   class="close-icon"
                                   src="@/assets/images/markerSpace/close.png" />
                              <video @click.stop="dialogFormVisible = false"
                                     :src="table.videoDoorhead"
                                     controls="controls" />
                            </div>
                          </div>
                        </el-upload>
                      </div>
                      <div class="upload">
                        <el-upload :disabled="disableds"
                                   :class="[hideUploadAdd == true ? 'hide' : '']"
                                   class="upload-demo"
                                   accept=".mp4"
                                   action="#"
                                   name="videoStore,2"
                                   :http-request="uploadVideo"
                                   ref="upload"
                                   :limit="fileLimits"
                                   :on-remove="handleRemove"
                                   :file-list="fileList"
                                   :on-exceed="handleExceed"
                                   :show-file-list="false"
                                   :headers="headers">
                          <div class="upload"
                               v-if="table.videoStore == ''">
                            <div class="texts titleSize">{{$t('CK_KS_64')}}</div>
                            <div class="img-s">
                              <img style="margin-top: 10px"
                                   src="@/assets/images/markerSpace/upload.png" />
                            </div>
                            <div class="texts"
                                 style="margin-top: 10px">
                              {{$t('CK_KS_63')}}
                            </div>
                          </div>
                          <div style="display: flex"
                               v-if="table.videoStore != ''">
                            <div class="img-ss width"
                                 @click.stop="dialogFormVisible = false">
                              <img v-if="!disableds"
                                   @click="delStops('videoStore', 2)"
                                   class="close-icon"
                                   src="@/assets/images/markerSpace/close.png" />
                              <video @click.stop="dialogFormVisible = false"
                                     :src="table.videoStore"
                                     controls="controls" />
                            </div>
                          </div>
                        </el-upload>
                      </div>
                      <div class="upload">
                        <el-upload :disabled="disableds"
                                   :class="[hideUploadAdd == true ? 'hide' : '']"
                                   class="upload-demo"
                                   accept=".mp4"
                                   action="#"
                                   name="videoProduct,3"
                                   :http-request="uploadVideo"
                                   ref="upload"
                                   :limit="fileLimits"
                                   :on-remove="handleRemove"
                                   :file-list="fileList"
                                   :on-exceed="handleExceed"
                                   :show-file-list="false"
                                   :headers="headers">
                          <div class="upload"
                               v-if="table.videoProduct == ''">
                            <div class="texts titleSize">{{$t('CK_KS_65')}}</div>
                            <div class="img-s">
                              <img style="margin-top: 10px"
                                   src="@/assets/images/markerSpace/upload.png" />
                            </div>
                            <div class="texts"
                                 style="margin-top: 10px">
                              {{$t('CK_KS_63')}}
                            </div>
                          </div>
                          <div style="display: flex"
                               v-if="table.videoProduct != ''">
                            <div class="img-ss width"
                                 @click.stop="dialogFormVisible = false">
                              <img v-if="!disableds"
                                   @click="delStops('videoProduct', 3)"
                                   class="close-icon"
                                   src="@/assets/images/markerSpace/close.png" />
                              <video @click.stop="dialogFormVisible = false"
                                     :src="table.videoProduct"
                                     controls="controls" />
                            </div>
                          </div>
                        </el-upload>
                      </div>
                    </div>
                    <div style="margin-top: 10px">
                      <div class="size-s">{{$t('CK_KS_48')}}：mp4</div>
                      <div class="size-s">
                        {{$t('CK_KS_66')}}
                      </div>
                    </div>
                  </div>
                  <div style="display: flex">
                    <el-form-item :label="`${$t('CK_KS_67')} (${getUserVideoSum1()}`+'/10)'"> </el-form-item>
                    <span style="color: #999; font-size: 12px; margin: 11px 0 0 20px">{{$t('CK_KS_45')}}</span>
                  </div>
                  <div class="upload-list">
                    <div style="display: flex">
                      <div class="upload">
                        <el-upload :disabled="disableds"
                                   :class="[hideUploadAdd == true ? 'hide' : '']"
                                   class="upload-demo"
                                   accept=".png, .jpg"
                                   action="#"
                                   name="imageDoorhead1,21"
                                   :http-request="uploadFile"
                                   ref="upload"
                                   :limit="fileLimits"
                                   :on-change="handleChange"
                                   :on-remove="handleRemove"
                                   :file-list="fileList"
                                   :on-exceed="handleExceed"
                                   :show-file-list="false"
                                   :headers="headers">
                          <div class="upload"
                               v-if="table.imageDoorhead1 == ''">
                            <div class="texts titleSize">{{$t('CK_KS_62')}}</div>
                            <div class="img-s">
                              <img style="margin-top:5px"
                                   src="@/assets/images/markerSpace/upload.png" />
                            </div>
                            <div class="texts">{{$t('CK_KS_46')}}</div>
                          </div>
                          <div class="imgWidth"
                               v-if="table.imageDoorhead1 && status1==5">
                            <el-image :src="table.imageDoorhead1"
                                      :preview-src-list="[table.imageDoorhead1]" />
                          </div>
                          <div style=" display: flex"
                               v-if="table.imageDoorhead1 != ''">
                            <div class="img-ss imgWidth"
                                 v-if="status1!=5"
                                 @click.stop="dialogFormVisible = false">
                              <img :src="table.imageDoorhead1" />
                              <div class="box-colors centers">
                                <div v-if="!disableds"
                                     @click="delStops('imageDoorhead1', 21)"
                                     @click.stop="dialogFormVisible = false"
                                     style="color: #fff; font-size: 14px">
                                  {{$t('CK_KS_47')}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-upload>
                      </div>
                      <div class="upload">
                        <el-upload :disabled="disableds"
                                   :class="[hideUploadAdd == true ? 'hide' : '']"
                                   class="upload-demo"
                                   accept=".png, .jpg"
                                   action="#"
                                   name="imageExperience1,23"
                                   :http-request="uploadFile"
                                   ref="upload"
                                   :limit="fileLimits"
                                   :on-change="handleChange"
                                   :on-remove="handleRemove"
                                   :file-list="fileList"
                                   :on-exceed="handleExceed"
                                   :show-file-list="false"
                                   :headers="headers">
                          <div class="upload"
                               v-if="table.imageExperience1 == ''">
                            <div class="texts titleSize">{{$t('CK_KS_68')}}</div>
                            <div class="img-s">
                              <img style="margin-top:5px"
                                   src="@/assets/images/markerSpace/upload.png" />
                            </div>
                            <div class="texts">{{$t('CK_KS_46')}}</div>
                          </div>
                          <div class="imgWidth"
                               v-if="table.imageExperience1 && status1==5">
                            <el-image :src="table.imageExperience1"
                                      :preview-src-list="[table.imageExperience1]" />
                          </div>
                          <div style=" display: flex"
                               v-if="table.imageExperience1 != ''">
                            <div class="img-ss imgWidth"
                                 @click.stop="dialogFormVisible = false"
                                 v-if="status1!=5">
                              <img :src="table.imageExperience1" />
                              <div class="box-colors centers">
                                <div v-if="!disableds"
                                     @click="delStops('imageExperience1', 23)"
                                     @click.stop="dialogFormVisible = false"
                                     style="color: #fff; font-size: 14px">
                                  {{$t('CK_KS_47')}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-upload>
                      </div>
                      <div class="upload">
                        <el-upload :disabled="disableds"
                                   :class="[hideUploadAdd == true ? 'hide' : '']"
                                   class="upload-demo"
                                   accept=".png, .jpg"
                                   action="#"
                                   name="imageMeeting1,25"
                                   :http-request="uploadFile"
                                   ref="upload"
                                   :limit="fileLimits"
                                   :on-change="handleChange"
                                   :on-remove="handleRemove"
                                   :file-list="fileList"
                                   :on-exceed="handleExceed"
                                   :show-file-list="false"
                                   :headers="headers">
                          <div class="upload"
                               v-if="table.imageMeeting1 == ''">
                            <div class="texts titleSize">{{$t('CK_KS_69')}}</div>
                            <div class="img-s">
                              <img style="margin-top:5px"
                                   src="@/assets/images/markerSpace/upload.png" />
                            </div>
                            <div class="texts">{{$t('CK_KS_46')}}</div>
                          </div>
                          <div class="imgWidth"
                               v-if="table.imageMeeting1 && status1==5">
                            <el-image :src="table.imageMeeting1"
                                      :preview-src-list="[table.imageMeeting1]" />
                          </div>
                          <div style=" display: flex"
                               v-if="table.imageMeeting1 != ''">
                            <div class="img-ss imgWidth"
                                 @click.stop="dialogFormVisible = false"
                                 v-if="status1!=5">
                              <img :src="table.imageMeeting1" />
                              <div class="box-colors centers">
                                <div v-if="!disableds"
                                     @click="delStops('imageMeeting1', 25)"
                                     @click.stop="dialogFormVisible = false"
                                     style="color: #fff; font-size: 14px">
                                  {{$t('CK_KS_47')}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-upload>
                      </div>
                      <div class="upload">
                        <el-upload :disabled="disableds"
                                   :class="[hideUploadAdd == true ? 'hide' : '']"
                                   class="upload-demo"
                                   accept=".png, .jpg"
                                   action="#"
                                   name="imageProduct1,27"
                                   :http-request="uploadFile"
                                   ref="upload"
                                   :limit="fileLimits"
                                   :on-change="handleChange"
                                   :on-remove="handleRemove"
                                   :file-list="fileList"
                                   :on-exceed="handleExceed"
                                   :show-file-list="false"
                                   :headers="headers">
                          <div class="upload"
                               v-if="table.imageProduct1 == ''">
                            <div class="texts titleSize">{{$t('CK_KS_70')}}</div>
                            <div class="img-s">
                              <img style="margin-top:5px"
                                   src="@/assets/images/markerSpace/upload.png" />
                            </div>
                            <div class="texts">{{$t('CK_KS_46')}}</div>
                          </div>
                          <div class="imgWidth"
                               v-if="table.imageProduct1 && status1==5">
                            <el-image :src="table.imageProduct1"
                                      :preview-src-list="[table.imageProduct1]" />
                          </div>
                          <div style=" display: flex"
                               v-if="table.imageProduct1 != ''">
                            <div class="img-ss imgWidth"
                                 @click.stop="dialogFormVisible = false"
                                 v-if="status1!=5">
                              <img :src="table.imageProduct1" />
                              <div class="box-colors centers">
                                <div v-if="!disableds"
                                     @click="delStops('imageProduct1', 27)"
                                     @click.stop="dialogFormVisible = false"
                                     style="color: #fff; font-size: 14px">
                                  {{$t('CK_KS_47')}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-upload>
                      </div>
                      <div class="upload">
                        <el-upload :disabled="disableds"
                                   :class="[hideUploadAdd == true ? 'hide' : '']"
                                   class="upload-demo"
                                   accept=".png, .jpg"
                                   action="#"
                                   name="imageLounge1,29"
                                   :http-request="uploadFile"
                                   ref="upload"
                                   :limit="fileLimits"
                                   :on-change="handleChange"
                                   :on-remove="handleRemove"
                                   :file-list="fileList"
                                   :on-exceed="handleExceed"
                                   :show-file-list="false"
                                   :headers="headers">
                          <div class="upload"
                               v-if="table.imageLounge1 == ''">
                            <div class="texts titleSize">{{$t('CK_KS_71')}}</div>
                            <div class="img-s">
                              <img style="margin-top:5px"
                                   src="@/assets/images/markerSpace/upload.png" />
                            </div>
                            <div class="texts">{{$t('CK_KS_46')}}</div>
                          </div>
                          <div class="imgWidth"
                               v-if="table.imageLounge1 && status1==5">
                            <el-image :src="table.imageLounge1"
                                      :preview-src-list="[table.imageLounge1]" />
                          </div>
                          <div style=" display: flex"
                               v-if="table.imageLounge1 != ''">
                            <div class="img-ss imgWidth"
                                 @click.stop="dialogFormVisible = false"
                                 v-if="status1!=5">
                              <img :src="table.imageLounge1" />
                              <div class="box-colors centers">
                                <div v-if="!disableds"
                                     @click="delStops('imageLounge1', 29)"
                                     @click.stop="dialogFormVisible = false"
                                     style="color: #fff; font-size: 14px">
                                  {{$t('CK_KS_47')}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-upload>
                      </div>
                    </div>
                    <div style="display: flex">
                      <div class="upload">
                        <el-upload :disabled="disableds"
                                   :class="[hideUploadAdd == true ? 'hide' : '']"
                                   class="upload-demo"
                                   accept=".png, .jpg"
                                   action="#"
                                   name="imageDoorhead2,22"
                                   :http-request="uploadFile"
                                   ref="upload"
                                   :limit="fileLimits"
                                   :on-change="handleChange"
                                   :on-remove="handleRemove"
                                   :file-list="fileList"
                                   :on-exceed="handleExceed"
                                   :show-file-list="false"
                                   :headers="headers">
                          <div class="upload"
                               v-if="table.imageDoorhead2 == ''">
                            <div class="texts titleSize">{{$t('CK_KS_62')}}</div>
                            <div class="img-s">
                              <img style="margin-top:5px"
                                   src="@/assets/images/markerSpace/upload.png" />
                            </div>
                            <div class="texts">{{$t('CK_KS_46')}}</div>
                          </div>
                          <div class="imgWidth"
                               v-if="table.imageDoorhead2 && status1==5">
                            <el-image :src="table.imageDoorhead2"
                                      :preview-src-list="[table.imageDoorhead2]" />
                          </div>
                          <div style=" display: flex"
                               v-if="table.imageDoorhead2 != ''">
                            <div class="img-ss imgWidth"
                                 @click.stop="dialogFormVisible = false"
                                 v-if="status1!=5">
                              <img :src="table.imageDoorhead2" />
                              <div class="box-colors centers">
                                <div v-if="!disableds"
                                     @click="delStops('imageDoorhead2', 22)"
                                     @click.stop="dialogFormVisible = false"
                                     style="color: #fff; font-size: 14px">
                                  {{$t('CK_KS_47')}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-upload>
                      </div>
                      <div class="upload">
                        <el-upload :disabled="disableds"
                                   :class="[hideUploadAdd == true ? 'hide' : '']"
                                   class="upload-demo"
                                   accept=".png, .jpg"
                                   action="#"
                                   name="imageExperience2,24"
                                   :http-request="uploadFile"
                                   ref="upload"
                                   :limit="fileLimits"
                                   :on-change="handleChange"
                                   :on-remove="handleRemove"
                                   :file-list="fileList"
                                   :on-exceed="handleExceed"
                                   :show-file-list="false"
                                   :headers="headers">
                          <div class="upload"
                               v-if="table.imageExperience2 == ''">
                            <div class="texts titleSize">{{$t('CK_KS_68')}}</div>
                            <div class="img-s">
                              <img style="margin-top:5px"
                                   src="@/assets/images/markerSpace/upload.png" />
                            </div>
                            <div class="texts">{{$t('CK_KS_46')}}</div>
                          </div>
                          <div class="imgWidth"
                               v-if="table.imageExperience2 && status1==5">
                            <el-image :src="table.imageExperience2"
                                      :preview-src-list="[table.imageExperience2]" />
                          </div>
                          <div style=" display: flex"
                               v-if="table.imageExperience2 != ''">
                            <div class="img-ss imgWidth"
                                 @click.stop="dialogFormVisible = false"
                                 v-if="status1!=5">
                              <img :src="table.imageExperience2" />
                              <div class="box-colors centers">
                                <div v-if="!disableds"
                                     @click="delStops('imageExperience2', 24)"
                                     @click.stop="dialogFormVisible = false"
                                     style="color: #fff; font-size: 14px">
                                  {{$t('CK_KS_47')}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-upload>
                      </div>
                      <div class="upload">
                        <el-upload :disabled="disableds"
                                   :class="[hideUploadAdd == true ? 'hide' : '']"
                                   class="upload-demo"
                                   accept=".png, .jpg"
                                   action="#"
                                   name="imageMeeting2,26"
                                   :http-request="uploadFile"
                                   ref="upload"
                                   :limit="fileLimits"
                                   :on-change="handleChange"
                                   :on-remove="handleRemove"
                                   :file-list="fileList"
                                   :on-exceed="handleExceed"
                                   :show-file-list="false"
                                   :headers="headers">
                          <div class="upload"
                               v-if="table.imageMeeting2 == ''">
                            <div class="texts titleSize">{{$t('CK_KS_69')}}</div>
                            <div class="img-s">
                              <img style="margin-top:5px"
                                   src="@/assets/images/markerSpace/upload.png" />
                            </div>
                            <div class="texts">{{$t('CK_KS_46')}}</div>
                          </div>
                          <div class="imgWidth"
                               v-if="table.imageMeeting2 && status1==5">
                            <el-image :src="table.imageMeeting2"
                                      :preview-src-list="[table.imageMeeting2]" />
                          </div>
                          <div style=" display: flex"
                               v-if="table.imageMeeting2 != ''">
                            <div class="img-ss imgWidth"
                                 @click.stop="dialogFormVisible = false"
                                 v-if="status1!=5">
                              <img :src="table.imageMeeting2" />
                              <div class="box-colors centers">
                                <div v-if="!disableds"
                                     @click="delStops('imageMeeting2', 26)"
                                     @click.stop="dialogFormVisible = false"
                                     style="color: #fff; font-size: 14px">
                                  {{$t('CK_KS_47')}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-upload>
                      </div>
                      <div class="upload">
                        <el-upload :disabled="disableds"
                                   :class="[hideUploadAdd == true ? 'hide' : '']"
                                   class="upload-demo"
                                   accept=".png, .jpg"
                                   action="#"
                                   name="imageProduct2,28"
                                   :http-request="uploadFile"
                                   ref="upload"
                                   :limit="fileLimits"
                                   :on-change="handleChange"
                                   :on-remove="handleRemove"
                                   :file-list="fileList"
                                   :on-exceed="handleExceed"
                                   :show-file-list="false"
                                   :headers="headers">
                          <div class="upload"
                               v-if="table.imageProduct2 == ''">
                            <div class="texts titleSize">{{$t('CK_KS_70')}}</div>
                            <div class="img-s">
                              <img style="margin-top:5px"
                                   src="@/assets/images/markerSpace/upload.png" />
                            </div>
                            <div class="texts">{{$t('CK_KS_46')}}</div>
                          </div>
                          <div class="imgWidth"
                               v-if="table.imageProduct2 && status1==5">
                            <el-image :src="table.imageProduct2"
                                      :preview-src-list="[table.imageProduct2]" />
                          </div>
                          <div style=" display: flex"
                               v-if="table.imageProduct2 != ''">
                            <div class="img-ss imgWidth"
                                 v-if="status1!=5"
                                 @click.stop="dialogFormVisible = false">
                              <img :src="table.imageProduct2" />
                              <div class="box-colors centers">
                                <div v-if="!disableds"
                                     @click="delStops('imageProduct2', 28)"
                                     @click.stop="dialogFormVisible = false"
                                     style="color: #fff; font-size: 14px">
                                  {{$t('CK_KS_47')}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-upload>
                      </div>
                      <div class="upload">
                        <el-upload :disabled="disableds"
                                   :class="[hideUploadAdd == true ? 'hide' : '']"
                                   class="upload-demo"
                                   accept=".png, .jpg"
                                   action="#"
                                   name="imageLounge2,30"
                                   :http-request="uploadFile"
                                   ref="upload"
                                   :limit="fileLimits"
                                   :on-change="handleChange"
                                   :on-remove="handleRemove"
                                   :file-list="fileList"
                                   :on-exceed="handleExceed"
                                   :show-file-list="false"
                                   :headers="headers">
                          <div class="upload"
                               v-if="table.imageLounge2 == ''">
                            <div class="texts titleSize">{{$t('CK_KS_71')}}</div>
                            <div class="img-s">
                              <img style="margin-top:5px"
                                   src="@/assets/images/markerSpace/upload.png" />
                            </div>
                            <div class="texts">{{$t('CK_KS_46')}}</div>
                          </div>
                          <div class="imgWidth"
                               v-if="table.imageLounge2 && status1==5">
                            <el-image :src="table.imageLounge2"
                                      :preview-src-list="[table.imageLounge2]" />
                          </div>
                          <div style=" display: flex"
                               v-if="table.imageLounge2 != ''">
                            <div class="img-ss imgWidth"
                                 @click.stop="dialogFormVisible = false"
                                 v-if="status1!=5">
                              <img :src="table.imageLounge2" />
                              <div class="box-colors centers">
                                <div v-if="!disableds"
                                     @click="delStops('imageLounge2', 30)"
                                     @click.stop="dialogFormVisible = false"
                                     style="color: #fff; font-size: 14px">
                                  {{$t('CK_KS_47')}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-upload>
                      </div>
                    </div>
                    <div style="margin-top: 10px">
                      <div class="size-s">{{$t('CK_KS_48')}}：jpg，png</div>
                      <div class="size-s">{{$t('CK_KS_49')}}</div>
                      <div class="size-s">
                        {{$t('CK_KS_50')}}
                      </div>
                    </div>
                  </div>
                </el-form>
              </div>
            </div>
            <div class="button-s"
                 @click="submit"
                 v-if="status == 1 && status_flag==true">{{text}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as apis from '@/api/index.js'
import sidebarUserInfo from '@/components/sidebarUserInfo'
import topBanner from '@/components/topBanner'
import * as api from '@/api/register'
import { getBankCardChoiceList } from '@/api/wallet'
import { getToken } from '@/util/auth'
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      srcList: [
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',
      ],
      navList: [
        { name: this.$t('ENU_APPROVE_B_232'), url: '/' },
        { name: this.$t('ENU_APPROVE_B_231'), url: '/' },
        { name: this.$t('ENU_APPROVE_B_251'), url: '/' },
        { name: this.$t('ENU_TRADE_T_901'), url: '/' },
        { name: this.$t('ENU_TRADE_T_822'), url: '/dealer' },
        { name: this.$t('w_1022'), url: '/superStudio' },
        { name: this.$t('w_1021'), url: '/markerSpace' },
      ],
      status_flag: true,
      text: this.$t('CK_KS_72'),
      storeTypes: 1,
      // 多张图
      fileList1: [],
      disableds: false,
      flags: 0,
      hideUploadAdd: false,
      regionParams: {
        label: 'name',
        value: 'id',
        children: 'children',
        expandTrigger: 'hover',
      },
      //上传后的文件列表
      fileList: [],
      fileType: ['png', 'jpg'],
      fileSize: 50,
      fileLimit: 50,
      fileLimits: 50,
      headers: {
        Authorization: getToken(),
      },
      areaData: [],
      status: null, //0未上传过 1已上传正在系统审核 2审核已成功只能查看无法编辑 3审核被驳回
      table: {
        storeType: 1,
        status: 0,
        storeName: '', //名称
        storeLevelVal: '', //创客空间等级
        licenseName: '', //营业执照名称
        creditCode: '', //统一社会信用代码
        legalName: '', //法人姓名
        legalIdCard: '', //法人身份证号
        licenseProvince: '', //营业执照省
        licenseCity: '', //营业执照市
        licenseCounty: '', //营业执照区
        licenseAddress: '', //营业执照详细地址
        storePerson: '', //联系人
        storePhone: '', //联系方式
        pkBank: '', //银行名称
        bankAccount: '', //银行账号
        storeProvince: '', //省
        storeCity: '', //市
        storeCounty: '', //区
        storeAddress: '', //详细地址
        storeSign: '', // 创客空间标志
        legalCardFront: '', //法人身份证正面
        legalCardBack: '', // 法人身份证反面
        license: '', // 营业执照
        storeAgreementList: [], // 协议列表
        videoDoorhead: '', // 门店门头视频
        videoStore: '', // 门店店内视频
        videoProduct: '', // 门店产品视频
        imageDoorhead1: '', // 门店门头图片1
        imageDoorhead2: '', // 门店门头图片2
        imageExperience1: '', // 门店体验区图片1
        imageExperience2: '', // 门店体验区图片2
        imageMeeting1: '', // 门店会议室图片1
        imageMeeting2: '', // 门店会议室图片2
        imageProduct1: '', // 门店产品图片1
        imageProduct2: '', // 门店产品图片2
        imageLounge1: '', // 门店休息室图片1
        imageLounge2: '', // 门店休息室图片2
        bankBranch: '', //开户支行
      },
      address: '', //营业执照地址
      address1: '', //详细地址
      bankCardChioceList: [],
      rules: {
        storeName: [
          { required: true, message: this.$t('S_C_70'), trigger: 'blur' },
        ],
        licenseName: [
          { required: true, message: this.$t('S_C_70'), trigger: 'blur' },
        ],
        creditCode: [
          { required: true, message: this.$t('S_C_70'), trigger: 'blur' },
        ],
        legalName: [
          { required: true, message: this.$t('S_C_70'), trigger: 'blur' },
        ],
        legalIdCard: [
          { required: true, message: this.$t('S_C_70'), trigger: 'blur' },
        ],
        licenseProvince: [
          {
            required: true,
            message: this.$t('CK_KS_38'),
            trigger: ['blur', 'change'],
          },
        ],
        licenseAddress: [
          { required: true, message: this.$t('S_C_70'), trigger: 'blur' },
        ],
        storeAddress: [
          { required: true, message: this.$t('S_C_70'), trigger: 'blur' },
        ],
        storeProvince: [
          {
            required: true,
            message: this.$t('CK_KS_38'),
            trigger: ['blur', 'change'],
          },
        ],
        pkBank: [
          { required: true, message: this.$t('CK_KS_38'), trigger: 'change' },
        ],
        bankAccount: [
          { required: true, message: this.$t('S_C_70'), trigger: 'blur' },
        ],
        bankBranch: [
          { required: true, message: this.$t('S_C_70'), trigger: 'blur' },
        ],
        storePerson: [
          { required: true, message: this.$t('S_C_70'), trigger: 'blur' },
        ],
        storePhone: [
          {
            required: true,
            message: this.$t('S_C_70'),
            trigger: 'blur',
          },
          { min: 11, message: this.$t('CK_KS_73'), trigger: 'blur' },
        ],
        storeSign: [{ required: true, message: ' ', trigger: 'blur' }],
      },
      storeLevelVal: '',
      memberCode: '',
      status1: null,
      memberName: '',
      cause: '', //驳回原因
      creatTime: '', // 创建时间
      checkTime: '', //审核时间
      showFlag: false,
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  components: { sidebarUserInfo, topBanner },
  watch: {},
  mounted() {
    this.getAllArea()
    this.getBankCardChoiceList()
    this.getIsRegiest()
    this.getUserStatus()
  },
  methods: {
    getIsRegiest() {
      api
        .checkAttest({
          storeType: 3,
        })
        .then((res) => {
          if (res.data == 1) {
            this.$alert(`${this.$t('w_1030')}?`, this.$t('w_0034'), {
              confirmButtonText: this.$t('w_0035'),
              type: 'warning',
              showClose:false,
            }).then(() => {
              this.$router.push({
                path: '/vipCenter',
                query: { id: 5, atabActiveId: 4 },
              })
              this.$emit('chnageMenu',4)
            })
          }
        })
    },
    getUserVideoSum() {
      let data1 = this.isEmpty(this.table.videoDoorhead) == false ? 1 : 0
      let data2 = this.isEmpty(this.table.videoStore) == false ? 1 : 0
      let data3 = this.isEmpty(this.table.videoProduct) == false ? 1 : 0
      return data1 + data2 + data3
    },
    getUserVideoSum1() {
      let data1 = this.isEmpty(this.table.imageDoorhead1) == false ? 1 : 0
      let data2 = this.isEmpty(this.table.imageExperience1) == false ? 1 : 0
      let data3 = this.isEmpty(this.table.imageMeeting1) == false ? 1 : 0
      let data4 = this.isEmpty(this.table.imageProduct1) == false ? 1 : 0
      let data5 = this.isEmpty(this.table.imageLounge1) == false ? 1 : 0
      let data6 = this.isEmpty(this.table.imageDoorhead2) == false ? 1 : 0
      let data7 = this.isEmpty(this.table.imageExperience2) == false ? 1 : 0
      let data8 = this.isEmpty(this.table.imageMeeting2) == false ? 1 : 0
      let data9 = this.isEmpty(this.table.imageProduct2) == false ? 1 : 0
      let data10 = this.isEmpty(this.table.imageLounge2) == false ? 1 : 0
      return (
        data1 +
        data2 +
        data3 +
        data4 +
        data5 +
        data6 +
        data7 +
        data8 +
        data9 +
        data10
      )
    },
    downloads() {
      const link = document.createElement('a')
      link.href =
        'https://hzs-in.oss-cn-beijing.aliyuncs.com/agreement/maker_space.pdf'
      link.target = '_blank'
      link.download = this.$t('CK_KS_74')

      // 模拟点击<a>元素
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    handleClose1() {
      this.showFlag = false
    },
    checks(check) {
      console.error(check)
      if (check == 1) {
        this.status = 1
        this.flags = 0
        this.disableds = true
        this.text = this.$t('CK_KS_72')
        this.status_flag = false
      }
      if (check == 2) {
        this.text = this.$t('CK_KS_75')
        this.status = 1
        this.flags = 0
        this.disableds = false
        this.showFlag = false
      }
    },
    submit() {
      // if(this.text=='重新认证'){
      //     this.status = 0
      //     this.flags = 0
      //     this.disableds = false
      //     this.text = '提交认证'
      //     return
      // }
      this.$refs['ruleForm'].validate((valid) => {
        if (!valid) {
          return false
        } else {
          if (this.isEmpty(this.table.storeSign)) {
            this.$message({
              message: this.$t('CK_KS_76'),
              type: 'warning',
            })
            return
          }
          if (this.isEmpty(this.table.legalCardFront)) {
            this.$message({
              message: this.$t('CK_KS_77'),
              type: 'warning',
            })
            return
          }
          if (this.isEmpty(this.table.legalCardBack)) {
            this.$message({
              message: this.$t('CK_KS_78'),
              type: 'warning',
            })
            return
          }
          if (this.isEmpty(this.table.license)) {
            this.$message({
              message: this.$t('CK_KS_79'),
              type: 'warning',
            })
            return
          }
          if (this.table.storeAgreementList.length == 0) {
            this.$message({
              message: this.$t('CK_KS_80'),
              type: 'warning',
            })
            return
          }
          if (this.isEmpty(this.table.videoDoorhead)) {
            this.$message({
              message: this.$t('CK_KS_81'),
              type: 'warning',
            })
            return
          }
          if (this.isEmpty(this.table.videoStore)) {
            this.$message({
              message: this.$t('CK_KS_82'),
              type: 'warning',
            })
            return
          }
          if (this.isEmpty(this.table.videoProduct)) {
            this.$message({
              message: this.$t('CK_KS_83'),
              type: 'warning',
            })
            return
          }
          if (this.isEmpty(this.table.imageDoorhead1)) {
            this.$message({
              message: `${this.$t('CK_KS_84')}1`,
              type: 'warning',
            })
            return
          }
          if (this.isEmpty(this.table.imageDoorhead2)) {
            this.$message({
              message: `${this.$t('CK_KS_84')}2`,
              type: 'warning',
            })
            return
          }
          if (this.isEmpty(this.table.imageExperience1)) {
            this.$message({
              message: `${this.$t('CK_KS_85')}1`,
              type: 'warning',
            })
            return
          }
          if (this.isEmpty(this.table.imageExperience2)) {
            this.$message({
              message: `${this.$t('CK_KS_85')}2`,
              type: 'warning',
            })
            return
          }
          if (this.isEmpty(this.table.imageMeeting1)) {
            this.$message({
              message: `${this.$t('CK_KS_86')}1`,
              type: 'warning',
            })
            return
          }
          if (this.isEmpty(this.table.imageMeeting2)) {
            this.$message({
              message: `${this.$t('CK_KS_86')}2`,
              type: 'warning',
            })
            return
          }
          if (this.isEmpty(this.table.imageProduct1)) {
            this.$message({
              message: `${this.$t('CK_KS_87')}1`,
              type: 'warning',
            })
            return
          }
          if (this.isEmpty(this.table.imageProduct2)) {
            this.$message({
              message: `${this.$t('CK_KS_87')}2`,
              type: 'warning',
            })
            return
          }
          if (this.isEmpty(this.table.imageLounge1)) {
            this.$message({
              message: `${this.$t('CK_KS_88')}1`,
              type: 'warning',
            })
            return
          }
          if (this.isEmpty(this.table.imageLounge2)) {
            this.$message({
              message: `${this.$t('CK_KS_88')}2`,
              type: 'warning',
            })
            return
          }
          api.markerSpaceSubmit(this.table).then((res) => {
            if (res.code == '200') {
              this.$message({
                type: 'success',
                message: res.msg,
              })
              this.getUserStatus()
            }
          })
        }
      })
    },
    isEmpty(v) {
      switch (typeof v) {
        case 'undefined':
          return true
        case 'string':
          if (v.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, '').length == 0)
            return true
          break
        case 'boolean':
          if (!v) return true
          break
        case 'number':
          if (0 === v || isNaN(v)) return true
          break
        case 'object':
          if (null === v || v.length === 0) return true
          for (var i in v) {
            return false
          }
          return true
      }
      return false
    },
    setAddress(res) {
      this.table.licenseProvince = res[0]
      this.table.licenseCity = res[1]
      this.table.licenseCounty = res[2]
    },
    setAddress1(res) {
      this.table.storeProvince = res[0]
      this.table.storeCity = res[1]
      this.table.storeCounty = res[2]
    },
    remove1() {
      console.error('000')
    },
    change1() {
      console.error('000')
    },
    upload1(file) {
      console.error(file)
      console.error('00')
    },
    newUploadImg(index, item) {
      this.imgUrl = ''
      this.$refs['upload'].$refs['upload-inner'].handleClick()
    },
    delStop(index) {
      let data = {
        storeType: this.storeTypes,
        filePath: this.table.storeAgreementList[index],
        fileType: 14,
      }
      api.delImg(data).then((res) => {
        if (res.code == '200') {
          this.table.storeAgreementList.splice(index, 1)
          this.$message({
            message: res.msg,
            type: 'success',
          })
        }
      })
    },
    delStops(item, num) {
      const time = item
      let data = {
        storeType: this.storeTypes,
        filePath: this.table[item],
        fileType: num,
      }
      api.delImg(data).then((res) => {
        if (res.code == '200') {
          this.table[item] = ''
          this.$message({
            message: res.msg,
            type: 'success',
          })
        }
      })
    },
    getUserStatus() {
      api.markerSpaceDetail({ storeType: this.storeTypes }).then((res) => {
        this.memberCode = res.data.memberCode
        this.memberName = res.data.memberName
        this.storeLevelVal = res.data.storeLevelVal
        this.$set(this.table, 'licenseName', res.data.licenseName)
        this.$set(this.table, 'creditCode', res.data.creditCode)
        this.$set(this.table, 'legalName', res.data.legalName)
        this.$set(this.table, 'legalIdCard', res.data.legalIdCard)
        this.$set(this.table, 'licenseProvince', res.data.licenseProvince)
        this.$set(this.table, 'licenseCity', res.data.licenseCity)
        this.$set(this.table, 'licenseCounty', res.data.licenseCounty)
        this.$set(this.table, 'licenseAddress', res.data.licenseAddress)
        this.$set(this.table, 'storePerson', res.data.storePerson)
        this.$set(this.table, 'storePhone', res.data.storePhone)
        this.$set(this.table, 'pkBank', res.data.pkBank)
        this.$set(this.table, 'bankAccount', res.data.bankAccount)
        this.$set(this.table, 'storeProvince', res.data.storeProvince)
        this.$set(this.table, 'storeCity', res.data.storeCity)
        this.$set(this.table, 'storeCounty', res.data.storeCounty)
        this.$set(this.table, 'storeAddress', res.data.storeAddress)
        this.$set(this.table, 'bankBranch', res.data.bankBranch)
        let address = [
          res.data.licenseProvince + '',
          res.data.licenseCity + '',
          res.data.licenseCounty + '',
        ]
        this.$set(this, 'address', address)
        let address1 = [
          res.data.licenseProvince + '',
          res.data.licenseCity + '',
          res.data.licenseCounty + '',
        ]
        this.$set(this, 'address1', address1)
        if (res.data.applyStatus == undefined) {
          this.status = 1
        } else {
          this.status = res.data.applyStatus
          // this.cause = res.data.rejectMsg
          const regex = new RegExp('<img', 'gi')
          if(res.data.rejectMsg){
            this.cause = res.data.rejectMsg.replace(
            regex,
            `<img style="max-width: 200px;"`
          )
          }
        
          this.status1 = res.data.applyStatus
          if (res.data.applyStatus != 0) {
            this.flags = 1
          }
          if (res.data.applyTime) {
            this.creatTime = res.data.applyTime
          }
          if (res.data.approveTime) {
            this.checkTime = res.data.approveTime
          }
          if (this.status > 0) {
            let arr = []
            let arr1 = []
            if (res.data.storeProvince) {
              arr1.push(res.data.storeProvince.toString())
              arr1.push(res.data.storeCity.toString())
              if (res.data.storeCounty) {
                arr1.push(res.data.storeCounty.toString())
              }
            }
            if (res.data.licenseProvince) {
              arr.push(res.data.licenseProvince.toString())
              arr.push(res.data.licenseCity.toString())
              if (res.data.licenseCounty) {
                arr.push(res.data.licenseCounty.toString())
              }
            }
            this.address = arr //营业执照省市区
            this.address1 = arr1 //联系人省市区
            this.table = {
              storeType: res.data.storeType,
              storeName: res.data.storeName, //名称
              storeLevelVal: res.data.storeLevelVal, //创客空间等级
              licenseName: res.data.licenseName, //营业执照名称
              creditCode: res.data.creditCode, //统一社会信用代码
              legalName: res.data.legalName, //法人姓名
              legalIdCard: res.data.legalIdCard, //法人身份证号
              licenseProvince: res.data.licenseProvince, //营业执照省
              licenseCity: res.data.licenseCity, //营业执照市
              licenseCounty: res.data.licenseCounty, //营业执照区
              licenseAddress: res.data.licenseAddress, //营业执照详细地址
              storePerson: res.data.storePerson, //联系人
              storePhone: res.data.storePhone, //联系方式
              pkBank: res.data.pkBank, //银行名称
              bankAccount: res.data.bankAccount, //银行账号
              storeProvince: res.data.storeProvince, //省
              storeCity: res.data.storeCity, //市
              storeCounty: res.data.storeCounty, //区
              storeAddress: res.data.storeAddress, //详细地址
              storeSign: res.data.storeSign ? res.data.storeSign : '', // 创客空间标志
              legalCardFront: res.data.legalCardFront
                ? res.data.legalCardFront
                : '', //法人身份证正面
              legalCardBack: res.data.legalCardBack
                ? res.data.legalCardBack
                : '', // 法人身份证反面
              license: res.data.license ? res.data.license : '', // 营业执照
              storeAgreementList: res.data.storeAgreementList
                ? res.data.storeAgreementList
                : '', // 协议列表
              videoDoorhead: res.data.videoDoorhead
                ? res.data.videoDoorhead
                : '', // 门店门头视频
              videoStore: res.data.videoStore ? res.data.videoStore : '', // 门店店内视频
              videoProduct: res.data.videoProduct ? res.data.videoProduct : '', // 门店产品视频
              imageDoorhead1: res.data.imageDoorhead1
                ? res.data.imageDoorhead1
                : '', // 门店门头图片1
              imageDoorhead2: res.data.imageDoorhead2
                ? res.data.imageDoorhead2
                : '', // 门店门头图片2
              imageExperience1: res.data.imageExperience1
                ? res.data.imageExperience1
                : '', // 门店体验区图片1
              imageExperience2: res.data.imageExperience2
                ? res.data.imageExperience2
                : '', // 门店体验区图片2
              imageMeeting1: res.data.imageMeeting1
                ? res.data.imageMeeting1
                : '', // 门店会议室图片1
              imageMeeting2: res.data.imageMeeting2
                ? res.data.imageMeeting2
                : '', // 门店会议室图片2
              imageProduct1: res.data.imageProduct1
                ? res.data.imageProduct1
                : '', // 门店产品图片1
              imageProduct2: res.data.imageProduct2
                ? res.data.imageProduct2
                : '', // 门店产品图片2
              imageLounge1: res.data.imageLounge1 ? res.data.imageLounge1 : '', // 门店休息室图片1
              imageLounge2: res.data.imageLounge2 ? res.data.imageLounge2 : '', // 门店休息室图片2
              bankBranch: res.data.bankBranch ? res.data.bankBranch : '', //支行
            }
          }
        }
      })
    },
    getAllArea() {
      api.allArea().then((res) => {
        this.areaData = res.data
      })
    },
    getBankCardChoiceList() {
      getBankCardChoiceList().then((res) => {
        this.bankCardChioceList = res.data
      })
    },
    handleRemove() {},
    // 文件状态改变时的钩子
    handleChange(file, fileList) {
      // 文件数量改变

      this.fileList = fileList
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error(this.$t('CK_KS_89'))
        this.fileList.pop()
      }
      return isLt2M
    },
    handleChange1(file, fileList) {
      // 文件数量改变
      this.fileList = fileList
      const isLt2M = file.size / 1024 / 1024 < 50
      if (!isLt2M) {
        this.$message.error(this.$t('CK_KS_90'))
        this.fileList.pop()
      }
      return isLt2M
    },
    handleClose(i) {
      this.fileList.splice(i, 1) //删除上传的文件
      if (this.fileList.length == 0) {
        //如果删完了
        this.fileflag = true //显示url必填的标识
        this.$set(this.rules.url, 0, {
          required: true,
          validator: this.validatorUrl,
          trigger: 'blur',
        }) //然后动态的添加本地方法的校验规则
      }
    },
    handleExceed() {
      // this.$message({
      //   type:'warning',
      //   message:'超出最大上传文件数量的限制！'
      // });return
    },
    //多张图上传方法
    uploadFile1(item) {
      const fileName = item.file.name
      const fileType = fileName.substring(fileName.lastIndexOf('.'))
      if (fileType !== '.jpg' && fileType !== '.png') {
        this.$message({
          message: this.$t('CK_KS_91'),
        })
      }
      // this.$showMessage('文件上传中........')
      let FormDatas = new FormData()
      FormDatas.append('file', item.file)
      FormDatas.append('storeType', this.storeTypes)
      FormDatas.append('fileType', 14)
      // FormDatas.append('image',result[0]);
      api.uploadImg(FormDatas).then((res) => {
        if (res.data) {
          this.table.storeAgreementList.push(res.data.url) //成功过后手动将文件添加到展示列表里
          // let i = this.fileList1.indexOf(res.data.url)
          // this.fileList1[i].id = res.data.name;//id也添加进去，最后整个大表单提交的时候需要的
        }
      })
    },
    uploadVideo(item) {
      const result = item.filename.split(',') //0名称 1列表名称
      let name = result[0]
      let img = result[1]
      const fileName = item.file.name
      const fileType = fileName.substring(fileName.lastIndexOf('.'))
      if (fileType !== '.mp4') {
        this.$message({
          message: this.$t('CK_KS_92'),
        })
        return
      }
      if (item.file.size / 1024 / 1024 > 50) {
        this.$message.warning(this.$t('CK_KS_93'))
        return false
      }
      let FormDatas = new FormData()
      FormDatas.append('file', item.file)
      FormDatas.append('storeType', this.storeTypes)
      FormDatas.append('fileType', img)
      api.uploadImg(FormDatas).then((res) => {
        if (res.data) {
          this.table[name] = res.data.url
          console.error(this.table)
        }
      })
    },
    uploadFile(item) {
      const result = item.filename.split(',') //0名称 1列表名称
      let name = result[0]
      let img = result[1]
      const fileName = item.file.name
      const fileType = fileName.substring(fileName.lastIndexOf('.'))
      if (fileType !== '.jpg' && fileType !== '.png') {
        this.$message({
          message: this.$t('CK_KS_91'),
        })
        return
      }
      if (item.file.size / 1024 / 1024 > 5) {
        this.$message.warning(this.$t('CK_KS_94'))
        return false
      }
      let FormDatas = new FormData()
      FormDatas.append('file', item.file)
      FormDatas.append('storeType', this.storeTypes)
      FormDatas.append('fileType', img)
      api.uploadImg(FormDatas).then((res) => {
        if (res.data) {
          this.table[name] = res.data.url
          console.error(this.table)
        }
      })
    },
    //上传成功后的回调
    handleSuccess() {},
  },
}
</script>
<style>
.hide .el-upload--picture-card {
  display: none !important;
}
</style>
<style lang="scss" scoped>
::v-deep .el-dialog_s .el-dialog {
  overflow: auto;
}
.img_width {
  text-align: center;
  ::v-deep img {
    width: auto;
  }
}
.class-s {
  top: 1890px !important;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 24px;
  color: #000;
}
::v-deep .el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 0;
}
::v-deep .el-dialog__title {
  font-size: 32px;
  color: #000;
  font-weight: 600;
}
.styles {
  border: none !important;
  color: #fff !important;
  background: #d61820;
}
.top-lists {
  display: flex;
  padding: 20px 0 0 0;
  .nav-view {
    cursor: pointer;
    padding: 0 26px;
    height: 44px;
    border-radius: 10px;
    border: 1px solid #999999;
    font-size: 14px;
    text-align: center;
    line-height: 44px;
    margin-right: 20px;
    color: #999;
  }
}
.width {
  position: relative;
  width: 128px;
  height: 128px;
}
.close-icon {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
}
.idCardWidth {
  width: 362px !important;
  height: 211px !important;
  position: relative;
  top: 0;
}
.card {
  width: 362px !important;
  height: 211px !important;
  position: relative;
}
.card img {
  width: 362px !important;
  height: 211px !important;
}
.card .el-image {
  width: 362px !important;
  height: 211px !important;
}
.centers {
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-list {
  //margin-left: -20px;
}
::v-deep .el-upload {
  display: flex;
}
.upload-demo {
  display: flex;
}
.text_s {
  position: absolute;
  top: 70px;
  right: 20px;
  width: 360px;
  line-height: 20px;
  color: #666;
  font-size: 12px;
}
.bolds {
  font-weight: bold;
}
.status-size {
  margin-top: 10px;
  font-size: 20px;
  color: #000;
}
.img_cen {
  padding: 200px 0;
  margin: 0 auto;
  text-align: center;
}
.status-img img {
  width: 80px;
  height: 80px;
}
.button-s {
  width: 290px;
  height: 66px;
  background: #d61820;
  border-radius: 10px 10px 10px 10px;
  text-align: center;
  cursor: pointer;
  line-height: 66px;
  font-size: 22px;
  color: #fff;
  margin: 60px auto;
}
::v-deep .el-form-item {
  margin-bottom: 20px;
}
.titleSize {
  margin: 10px 0 5px 0 !important;
  color: #000 !important;
}
.idCard .el-image {
  width: 362px;
  height: 211px;
}
.idCard img {
  width: 362px;
  height: 211px;
}
.line-s {
  ////padding: 0 20px;
  //margin: 40px 0;
  //background: rgba(0, 0, 0, 0.1);
  //height: 4px;
  //position: absolute;
  //width: 100%;
  //top: 1800px;
}
.size-s {
  color: #c8161d;
  font-size: 12px;
  margin-bottom: 5px;
}
.texts {
  color: #999;
  font-size: 12px;
  margin-top: 20px;
}
.imgWidth {
  position: relative;
  width: 128px;
  height: 128px;
}
video {
  position: absolute;
  width: 128px !important;
  height: 128px !important;
  margin-top: 0 !important;
  left: 0;
  top: 0;
}
.imgWidth img {
  position: absolute;
  width: 128px !important;
  height: 128px !important;
  margin-top: 0 !important;
  left: 0;
  top: 0;
}
.imgWidth1 {
  position: relative;
  flex-wrap: wrap;
}
.imgWidth1 .el-image {
  width: 128px !important;
  height: 128px !important;
  margin-right: 10px;
}
.imgWidth .el-image {
  position: absolute;
  width: 128px !important;
  height: 128px !important;
  margin-top: 0 !important;
  left: 0;
  top: 0;
}
.box-colors {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 6px 6px 6px 6px;
  width: 128px;
  height: 128px;
  position: absolute;
}
.img-ss {
  margin-right: 20px;
}
.img-s img {
  width: 40px;
  height: 40px;
  margin-top: 25px;
}
.upload {
  width: 128px;
  height: 128px;
  background: rgba(204, 204, 204, 0.3);
  border-radius: 6px 6px 6px 6px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
  margin-right: 20px;
}
::v-deep .area .el-input__inner {
  width: 820px;
  height: 65px;
  font-size: 18px;
}
.form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
::v-deep .el-form--label-top .el-form-item__label {
  padding-bottom: 0;
}
::v-deep .el-form-item__label {
  font-size: 16px;
  color: #000;
}
::v-deep .centers .el-input__inner {
  text-align: center;
}
::v-deep .inputs .el-input__inner {
  width: 378px;
  height: 65px;
  font-size: 18px;
}
.flexs {
  display: flex;
  //padding-right: 60px;
  background-color: #f9f9f9;
}
.banners {
  flex: 1;
  margin-top: 20px;
}
.content {
  width: 100%;
  padding: 30px 0;
  background-color: #fff;
  margin-top: 20px;
  box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
  border-radius: 10px 10px 10px 10px;
  .systemTitle {
    display: flex;
    justify-content: space-between;
    //margin-top: 25px;
    position: relative;
    .button {
      background-color: #ffad41;
      color: #fff;
      font-size: 16px;
      height: 48px;
      border-radius: 10px 10px 10px 10px;
      line-height: 20px;
      position: absolute;
      right: 80px;
      top: 10px;
    }
    .titles {
      margin: 25px auto auto auto;
      color: #333;
      font-size: 28px;
      font-weight: 600;
    }
  }
  .title {
    //height: 49px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    padding-bottom: 10px;
    .name {
      margin-left: 10px;
      font-weight: 600;
    }
    .line {
      width: 5px;
      height: 19px;
      background: #c8161d;
    }
  }
}
</style>
